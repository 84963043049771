import React, { useEffect, useState } from "react";
import Services1 from "../../../content/images/services.png";
import Background from "../../../content/images/bg-1.jpg";

export default function Values({ texts }: any) {
  const [lang, setLang] = useState("pt");

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLang(lang);
    }
  }, []);

  return (
    <div
      id="services"
      className="relative min-h-screen bg-black pb-[5rem] bg-center bg-cover"
    >
      <div
        className="w-full h-[10rem] flex justify-center items-center bg-[50%_75%] bg-cover"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-70"></div>
        <div className="relative border-b-4 border-[var(--primary)] w-[10rem] text-white font-bold text-center text-[1.5rem]">
          {lang === "pt" ? "OS NOSSOS" : "OUR"}
        </div>
      </div>
      <div className="relative w-full h-full pt-[1rem] px-[8vw]">
        <h2 className="text-center font-bold text-[2rem] text-[var(--primary)]">
          {lang === "pt" ? "Serviços" : "Services"}
        </h2>
        <div className="w-full flex flex-col mt-[5rem] gap-[3rem]">
          <h3 className="text-left font-bold text-[2rem] text-[var(--primary)]">
            Parking
          </h3>
          <div className="w-full flex oito:flex-row flex-col gap-[5vw]">
            <div className="bg-white rounded-[25px] w-full border-4 border-[var(--primary)] p-8 text-center flex flex-col items-center space-y-4 group">
              {lang === "pt" ? (
                <p className="text-black text-[1.5rem] seis:text-[2rem] text-center">
                  Parque{" "}
                  <span className="font-bold border-b-4 border-[var(--primary)]">
                    Coberto
                  </span>
                </p>
              ) : (
                <p className="text-black text-[1.5rem] seis:text-[2rem] text-center">
                  <span className="font-bold border-b-4 border-[var(--primary)]">
                    Covered
                  </span>{" "}
                  Park
                </p>
              )}
              {lang === "pt" ? (
                <p className="text-[.9rem] font-poppinsM text-left transition-all duration-300 group-hover:max-h-full max-h-full sete:max-h-0 overflow-hidden">
                  Parque indoor fechado a 5 minutos do aeroporto -
                  estacionamento coberto <i>low cost</i> junto ao aeroporto de
                  faro. Enquanto está a apanhar sol na praia o seu carro estará
                  protegido no parque indoor.
                </p>
              ) : (
                <p className="text-[.9rem] font-poppinsM text-left transition-all duration-300 group-hover:max-h-full max-h-full sete:max-h-0  overflow-hidden">
                  Indoor park 5 minutes from the airport - covered and{" "}
                  <i>low cost</i>
                  parking near Faro airport. While you are catching the sun on
                  the beach your car will be protected in the indoor park.
                </p>
              )}
              <p className="font-bold text-[1.5rem] mt-8">
                <span className="mr-2 text-[.8rem]">desde</span>
                {texts?.coveredPark}€/{lang === "pt" ? "dia" : "day"}
              </p>
              <button
                className="bg-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-white mt-8 border-4 border-[transparent] hover:border-[var(--primary)] hover:bg-white hover:text-black transition-all duration-300"
                onClick={() => (window.location.href = "/book")}
              >
                {lang === "pt" ? "RESERVAR" : "BOOK"}
              </button>
            </div>
            <div className="bg-white rounded-[25px] w-full border-4 border-[var(--primary)] p-8 space-y-4 text-center flex flex-col items-center group">
              {lang === "pt" ? (
                <p className="text-black text-[1.5rem] seis:text-[2rem] text-center">
                  Parque{" "}
                  <span className="font-bold border-b-4 border-[var(--primary)]">
                    Descoberto
                  </span>
                </p>
              ) : (
                <p className="text-black text-[1.5rem] seis:text-[2rem] text-center">
                  <span className="font-bold border-b-4 border-[var(--primary)]">
                    Uncovered
                  </span>{" "}
                  Park
                </p>
              )}
              {lang === "pt" ? (
                <p className="text-[.9rem] font-poppinsM text-left transition-all duration-300 group-hover:max-h-full max-h-full sete:max-h-0  overflow-hidden">
                  Parque outdoor a 5 minutos do aeroporto - estacionamento
                  descoberto <i>low cost</i> junto ao aeroporto de faro.
                </p>
              ) : (
                <p className="text-[.9rem] font-poppinsM text-left transition-all duration-300 group-hover:max-h-full max-h-full sete:max-h-0  overflow-hidden">
                  Outdoor park 5 minutes from the airport - uncovered and{" "}
                  <i>low cost</i>
                  parking near Faro airport.
                </p>
              )}
              <p className="font-bold text-[1.5rem] mt-8">
                <span className="mr-2 text-[.8rem]">desde</span>
                {texts?.uncoveredPark}€/{lang === "pt" ? "dia" : "day"}
              </p>
              <button
                className="bg-white border-4 border-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-black mt-8 hover:bg-[var(--primary)] hover:text-white transition-all duration-300"
                onClick={() => (window.location.href = "/book")}
              >
                {lang === "pt" ? "RESERVAR" : "BOOK"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-[5rem] w-full flex sete:flex-row flex-col gap-[5rem] px-[8vw] ">
        <div className="flex flex-col gap-6">
          <h3 className="text-left font-bold text-[2rem] text-[var(--primary)]">
            Valet Parking
          </h3>
          <p className="text-[1.5rem] font-bold text-white">
            {lang === "pt"
              ? "Deixe seu carro no aeroporto conosco e viaje sem problemas, cuidaremos do resto ;)"
              : "Leave your car at the airport with us and fly without any problems, we'll take care of the rest ;)"}
          </p>
          <div className="flex flex-col gap-4 p-4 text-center items-center bg-[var(--primary)] rounded-[25px]  group">
            <p className="font-bold text-[2rem] text-white">
              {texts?.baseValue}€
            </p>
            <p className="text-[.9rem] font-poppinsM text-left text-white">
                {
                    lang === 'pt' ? 'Deixe o carro no terminal do aeroporto de faro, nós estacionamos por si. Quando voltar, o seu carro estará a sua espera dentro do terminal das partidas no aeroporto internacional de faro.'
                    : 'Leave the car at the Faro airport terminal, we park for you. When you return, your car will be waiting for you inside the departure terminal at Faro International Airport.'
                }
            </p>
            <button
              className="bg-white border-4 border-[transparent] rounded-[10px] w-[15rem] py-4 font-bold text-black hover:border-white hover:bg-[var(--primary)] hover:text-white transition-all duration-300"
              onClick={() => (window.location.href = "/book")}
            >
              {lang === "pt" ? "RESERVAR" : "BOOK"}
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center w-full">
          <img src={Services1} alt="service2" className="w-[20rem]" />
        </div>
      </div>
    </div>
  );
}
