import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';

export default function Contact() {
    const [lang, setLang] = useState("pt");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    const changeLanguage = (lang: string) => {
        localStorage.setItem("lang", lang);
        setLang(lang);
    };

    async function submit() {
        if (!email || email === "" || !email.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
            await displayModal("Aviso", "Por favor, insira um e-mail válido.");
            return;
        }

        if (!subject || subject === "") {
            await displayModal("Aviso", "Por favor, insira um assunto.");
            return;
        }

        if (!message || message === "") {
            await displayModal("Aviso", "Por favor, insira uma mensagem.");
            return;
        }

        const emailParams = { email: email, subject: subject, message: message };

        emailjs.send('service_qctwd7l', 'template_jrzznes', emailParams, 'jeStFGZ-400kFvT_-').then(() => {
            alert("Formulário enviado com sucesso!");
        }).catch((error) => {
            alert("Ocorreu um erro ao enviar o email. Por favor, tente novamente.");
            console.log(error)
        })
    }

    function displayModal(title: string, message: string) {
        return new Promise((resolve) => {
            let modal = document.getElementById("modal");
            let modalTitle = document.getElementById("modal-title");
            let modalText = document.getElementById("modal-text");
            let btn = document.getElementById("confirmBtn");
            if (!modal || !modalTitle || !modalText || !btn) {
                resolve(false);
                return;
            }

            modalTitle.textContent = title;
            modalText.textContent = message;
            modal.classList.replace('hidden', 'block');
            btn.onclick = function () {
                if (!modal) {
                    resolve(false);
                    return;
                }
                modal.classList.replace('block', 'hidden');
                resolve(true); // Resolve the promise when the modal is closed
            };
        });
    };

    return (
        <div className='px-[8vw] flex justify-center min-h-screen font-poppins'>
            <div id="modal" className="fixed inset-0 z-10 hidden bg-black bg-opacity-70">
                <div className="modal-content absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 h-64 text-white bg-primary border border-gray-300 p-5 flex flex-col items-center justify-center rounded-3xl bg-[var(--primary)]">
                    <p id="modal-title" className="mb-4"></p>
                    <p id="modal-text"></p>
                    <button id="confirmBtn" className="h-8 mt-4 text-white transition duration-200 ease-in bg-transparent border border-white rounded-full outline-none w-28 hover:bg-white hover:text-primary focus:outline-none hover:text-black">OK</button>
                </div>
            </div>
            <div className="w-[20rem] sete:w-[30rem] pt-20">
                <div className="space-y-4 text-[1rem]" >
                    <div className="flex rounded-full h-[3rem] my-4 w-full border-2 border-black items-center justify-around">
                        <div
                            onClick={() => changeLanguage("pt")}
                            className={`cursor-pointer w-full items-center flex justify-center rounded-full mx-4 ${lang === "pt" ? "bg-[var(--primary)] text-white" : ""
                                }`}
                        >
                            Português
                        </div>
                        <div
                            onClick={() => changeLanguage("en")}
                            className={`cursor-pointer w-full items-center flex justify-center rounded-full mx-4 ${lang === "en" ? "bg-[var(--primary)] text-white" : ""
                                }`}
                        >
                            English
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <label htmlFor="email" className="w-1/3">
                            Email:
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="|"
                            value={email}
                            className="w-2/3 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center gap-4">
                        <label htmlFor="subject" className="w-1/3">
                            {lang === "pt" ? "Assunto" : "Subject"}:
                        </label>
                        <input
                            type="text"
                            name="subject"
                            placeholder="|"
                            value={subject}
                            className="w-2/3 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </div>
                    <div className="flex items-start gap-4">
                        <label htmlFor="message" className="w-1/3">
                            {lang === "pt" ? "Mensagem" : "Message"}:
                        </label>
                        <textarea
                            name="message"
                            placeholder="|"
                            value={message}
                            rows={10}
                            cols={10}
                            className="resize-none w-2/3 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-[20px] border-black"
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <div className="flex gap-4 text-center">
                        <button
                            onClick={submit}
                            className="w-full py-1 sete:py-3 bg-[var(--primary)] text-white rounded-full hover:bg-blue-600"
                        >
                            {lang === "pt" ? "Enviar" : "Send"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

