import React, { useEffect, useState } from 'react';

import Logo from '../content/images/redpark.png';

export default function About({ texts }: any) {
    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='px-[8vw] min-h-screen py-[5rem] font-poppins'>
            <div className='flex milecem:flex-row flex-col-reverse milecem:items-center justify-between gap-[2rem] mb-4 text-left'>
                <h1 className='text-left w-full sete:w-[40rem] text-[1.2rem] quatro:text-[1.5rem] cinco:text-[2rem] sete:text-[2.5rem] font-bold text-balance uppercase'>{lang === "pt" ? "SOBRE O REDPARK, ESTACIONAMENTO AEROPORTO" : "ABOUT REDPARK, AIRPORT PARKING"}</h1>
                <img src={Logo} alt="logo" className='w-[15rem] seis:w-[20rem]' />
            </div>
            <div className='font-poppinsM'>
                Fundado em 2016 e com 100% de capital nacional, a Multipark SA surgiu com o objetivo de melhorar os serviços de estacionamento em aeroportos. Assim como a Lamborghini surgiu para enfrentar desafios encontrados por Ferruccio Lamborghini, o Airpark (primeira marca do Grupo Multipark) foi criado para oferecer soluções inovadoras e diferenciadas, melhorando a experiência dos nossos clientes. Queremos renovar o conceito de estacionamento em Portugal!
                <br /><br />
                Desenvolvemos um sistema de valet parking, em que um motorista especializado recolhe e entrega o seu veículo diretamente nas partidas do aeroporto, garantindo a sua comodidade. Também implementámos gravação em vídeo do seu veículo no terminal, para a sua segurança, e criámos um sistema de GPS que permite saber onde está a sua viatura em tempo real. Desenvolvemos ainda o valet VIP, que o leva até à porta de embarque e o aguarda na alfândega ao regresso.
                <br /><br />
                Em 2020, o grupo Multipark cresceu com o RedPark, que complementa os serviços de estacionamento no Aeroporto de Lisboa e alarga a atuação para outras áreas da cidade, como estações de comboio, cais de embarque e hotéis, com um serviço de valet personalizado.
                <br /><br />
                Em 2022 chegámos ao Aeroporto de Faro com o Airpark, contando já com mais de 30 mil clientes satisfeitos em Faro e oferecendo serviços de estacionamento coberto, descoberto, valet parking e lavagens.
                <br /><br />
                Temos o prazer de anunciar que, a partir de novembro, estaremos a oferecer o nosso serviço de estacionamento também no Aeroporto de Faro! Com o RedPark Faro, não precisa de se preocupar com encontrar um parque ou estacionar a sua viatura. Basta reservar o estacionamento e entregar a viatura diretamente no terminal de embarque.
                <br /><br />
                O RedPark é um parque low cost que oferece valet parking no Aeroporto de Faro por apenas 10€, com opções de estacionamento coberto e descoberto, ao melhor preço do mercado.
                <br /><br />
                Reservar o seu estacionamento é simples: basta clicar no botão "Reservar", indicar as datas e horas da sua viagem, e receberá a confirmação da sua reserva por email.
                <br /><br />
                No dia, apenas precisa de ligar 10 minutos antes e conduzir a viatura até ao terminal. Pode também acrescentar serviços de lavagem à sua reserva e encontrar a sua viatura limpa ao regresso.
                <br /><br />
                Ao chegar ao terminal, será recebido por um funcionário do RedPark identificado com camisola vermelha, que fará uma breve vistoria e levará o seu veículo para o parque. Todos os motoristas têm formação em condução defensiva.
                <br /><br />
                O parque do RedPark em Faro está localizado perto do aeroporto, é vedado e vigiado 24 horas, e conta com seguro contra incêndio e seguro multirriscos. Com sistemas de alarme e videovigilância, garantimos a segurança do seu veículo.
                <br /><br />
                Reserve agora o seu estacionamento no Aeroporto de Faro com o RedPark e aproveite este novo serviço!
            </div>
        </div>
    );
}

