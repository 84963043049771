import React, { useEffect, useState } from "react";

import Logo from "../content/images/redpark.png";

export default function Terms({ texts }: any) {
  const [lang, setLang] = useState("pt");

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLang(lang);
    }
  }, []);

  return (
    <div className="px-[8vw] min-h-screen py-[5rem] font-poppins">
      <div className="flex milecem:flex-row flex-col-reverse milecem:items-center justify-between gap-[2rem] mb-4 text-left">
        {lang === "pt" ? (
          <h1 className="text-left w-full text-[1.2rem] quatro:text-[1.5rem] cinco:text-[2rem] sete:text-[2.5rem] font-bold">
            TERMOS E CONDIÇÕES <br /> GERAIS DE FUNCIONAMENTO
          </h1>
        ) : (
          <h1 className="text-left w-full text-[1.2rem] quatro:text-[1.5rem] cinco:text-[2rem] sete:text-[2.5rem] font-bold">
            GENERAL TERMS & CONDITIONS <br /> OF RED PARK OPERATION
          </h1>
        )}
        <img src={Logo} alt="logo" className="w-[15rem] seis:w-[20rem]" />
      </div>
      <div className="whitespace-pre-wrap font-poppinsM">
        <p>
          <strong>1. DEFINIÇÕES</strong>
          <br />
          <br />
          Para todos os efeitos, as partes acordam as seguintes definições:
        </p>
        <ul>
          <li>
            <strong>1.1. Empresa:</strong> Multipark SA;
          </li>
          <li>
            <strong>1.2. Parque:</strong> Redpark ou Parque, coberto e
            descoberto, vedados e fechados, com alarme, vigilância 24 horas por
            dia, 365 dias por ano e com admissão restrita a terceiros, situado
            na zona circundante ao aeroporto de Faro ou num raio de 10
            quilómetros, sem qualquer prejuízo para o cliente;
          </li>
          <li>
            <strong>1.3. Cliente:</strong> Pessoa singular ou coletiva que
            utilizará o parque nas condições previstas neste acordo;
          </li>
          <li>
            <strong>1.4. Veículo:</strong> Veículo automóvel de que o cliente, a
            qualquer título legítimo, detenha a disponibilidade, não se
            incluindo qualquer bem acessório não incorporado no mesmo, e que
            seja apropriável;
          </li>
          <li>
            <strong>1.5. Aeroporto:</strong> O Aeroporto Internacional de Faro,
            em cujo raio de 10 km o Parque Redpark se encontra localizado.
          </li>
        </ul>

        <p>
          <br />
          <strong>2. OBJETO DO CONTRATO</strong>
          <br />
          <br />
          <strong>2.1.</strong> O presente contrato tem por objeto a
          disponibilização das instalações do Parque pelos períodos de duração
          acordados entre o Cliente e a Empresa, por períodos diários e
          observadas as demais condições previstas nas cláusulas seguintes.
          <br />
          <br />
          <strong>2.2.</strong> O acesso ao Parque de Estacionamento encontra-se
          condicionado à aceitação das condições estipuladas no presente
          regulamento de “NORMAS E CONDIÇÕES GERAIS DE FUNCIONAMENTO REDPARK”.
        </p>

        <p>
          <br />
          <strong>3. DURAÇÃO DO CONTRATO</strong>
          <br />
          <br />
          <strong>3.1.</strong> O presente contrato terá a duração acordada em
          cada caso entre o Cliente e a Empresa, sem prejuízo de esse período
          poder ser alargado, mediante a comunicação escrita prévia no mínimo de
          24 horas; se a mesma não for realizada, o parque será taxado a 10
          euros ao dia.
          <br />
          <br />
          <strong>3.2.</strong> A reserva terá sempre de ser paga na totalidade,
          tendo uma taxa de 10 euros na eventualidade do Cliente terminar a
          reserva mais cedo, salvo exceções de comunicação escrita prévia no
          mínimo de 24 horas.
          <br />
          <br />
          <strong>3.3.</strong> Cancelamentos terão de ser comunicados por
          escrito com 24 horas de antecedência, com penalização de 50% da
          reserva sem o mesmo.
        </p>

        <p>
          <br />
          <strong>4. CONDIÇÕES</strong>
          <br />
          <br />
          <strong>4.1.</strong> Sem prejuízo do demais previsto neste Contrato,
          com a subscrição do mesmo, serão assegurados ao Cliente os seguintes
          benefícios:
          <br />
          <br />
          <ul>
            <li>
              <strong>4.2.</strong> Guarda do veículo nas instalações do Parque,
              pelo período de duração do presente Contrato;
            </li>
            <li>
              <strong>4.3.</strong> A recolha e entrega do Veículo do Cliente no
              Aeroporto, com uma antecedência mínima de 2 horas (podendo ser com
              menos antecedência, sendo aplicada uma taxa de urgência de 10
              euros), a ser executada por colaboradores da Empresa, devidamente
              habilitados para o efeito;
            </li>
            <li>
              <strong>4.4.</strong> Lavagem do veículo, desde que
              especificamente pedida pelo Cliente, ou oferecido pela Empresa;
            </li>
            <li>
              <strong>4.5.</strong> Reparações do veículo, desde que
              especificamente pedidos pelo Cliente.
            </li>
            <li>
              <strong>4.6.</strong> A atribuição dos benefícios conferidos no
              ponto anterior pressupõe que:
            </li>
            <ul>
              <li>
                <strong>4.6.1.</strong> Será entregue pelo Cliente a
                representantes habilitados da Empresa a chave da Viatura, na sua
                recolha no Aeroporto, a qual ficará estacionada nas instalações
                do Parque.
              </li>
              <li>
                <strong>4.6.2.</strong> Os colaboradores habilitados da Empresa
                poderão, em qualquer momento, proceder a movimentações da
                Viatura dentro do recinto do Parque, de forma a permitir a
                movimentação de outras viaturas que entretanto sejam levantadas
                por outros Clientes ou, em qualquer caso, permitir a otimização
                dos espaços de parqueamento do Parque;
              </li>
              <li>
                <strong>4.6.3.</strong> A autorização de condução do veículo na
                via pública por colaboradores da Empresa, sendo esta autorização
                válida para o trajeto entre o Parque e o Aeroporto, podendo
                ainda, em casos excecionais (cortes de estrada, tráfego intenso,
                desvio policial, etc.), ser movimentada num outro trajeto.
              </li>
            </ul>
          </ul>
        </p>
        <br />

        <p>
          <strong>5. CONDIÇÕES COMPLEMENTARES E PREÇO</strong>
          <br />
          <br />
          <strong>5.1.</strong> O levantamento do veículo dependerá do
          procedimento acordado entre o Cliente e o Parque, numa das seguintes
          modalidades:
          <br />
          <br />
          <ul>
            <li>
              <strong>5.1.1.</strong> Indicação prévia, feita no momento da
              reserva, por comunicação telefónica, on-line ou via e-mail,
              indicando a data e hora em que o regresso do Cliente se fará, com
              indicação da modalidade de entrega aos colaboradores no Aeroporto
              devidamente habilitados, fardados e identificados, observando-se
              sempre os procedimentos de check-in referidos na cláusula 8ª;
            </li>
            <li>
              <strong>5.1.2.</strong> Na falta de qualquer dos procedimentos
              prévios previstos no ponto anterior, o levantamento da Viatura
              poderá ainda ser efetuado por comunicação a um dos colaboradores
              devidamente identificados da Empresa presentes no Aeroporto, ou
              por contacto telefónico para a Empresa. O Cliente aceita que,
              nesse caso, o procedimento de levantamento da viatura poderá
              sofrer atrasos e ser aplicada a taxa de urgência no valor de 10
              euros;
            </li>
            <li>
              <strong>5.1.3.</strong> O preço a pagar pelo Cliente será o que
              consta na tabela de preços exposta no site www.redpark.pt e
              previamente conhecida do Cliente, e corresponderá ao número de
              dias multiplicado por cada tarifa diária, sem fracionamentos. O
              pagamento será efetuado no ato de entrega da viatura, tendo em
              conta o parque e serviços adicionais escolhidos e o período de
              utilização utilizado.
            </li>
          </ul>
        </p>
        <br />

        <p>
          <strong>6. OBRIGAÇÕES DO CLIENTE</strong>
          <br />
          <br />
          <ul>
            <li>
              <strong>6.1.</strong> O Cliente obriga-se perante a Empresa a:
            </li>
            <ul>
              <li>
                <strong>6.1.1.</strong> Entregar o Veículo em plenas condições
                mecânicas (incluindo pneus e travões), em pleno estado de
                funcionamento e com todas as revisões técnicas e inspeções, bem
                como com toda a documentação legalmente exigível, que titule de
                modo adequado a disponibilidade do veículo pelo Cliente e a sua
                deslocação pelos colaboradores da Empresa nos termos da cláusula
                4.1.2). A entrega deverá ser feita por meio da entrega de chaves
                do veículo que permitam o acesso a este, o comando de alarme se
                este for autónomo e ainda qualquer outro comando que permita
                desativar o alarme em caso de necessidade;
              </li>
              <li>
                <strong>6.1.2.</strong> Permitir o acesso ao interior do Veículo
                e a condução do mesmo por parte dos colaboradores da Empresa,
                nos termos e para os efeitos da cláusula 4.2.2;
              </li>
              <li>
                <strong>6.1.3.</strong> Autorizar a utilização do seguro do
                Veículo durante o trajeto entre o Aeroporto e o Parque e
                vice-versa;
              </li>
              <li>
                <strong>6.1.4.</strong> Qualquer outro acesso ao Veículo fica
                excluído, com exceção de situações resultantes de ordens
                provenientes de entidades com poderes de autoridade,
                designadamente de Polícia e funcionários judiciais ou com
                poderes de ordem pública;
              </li>
              <li>
                <strong>6.1.5.</strong> Levar a efeito os procedimentos de
                Entrega e Recolha previstos na cláusula 8, subscrevendo a
                documentação correspondente;
              </li>
              <li>
                <strong>6.1.6.</strong> Autorizar o Parque, por intermédio dos
                seus funcionários, a retirar do Veículo, após entrega deste,
                qualquer elemento incorporado mas facilmente destacável, tal
                como antenas e outros acessórios auto, o qual deverá ser reposto
                na viatura no momento do levantamento desta;
              </li>
              <li>
                <strong>6.1.7.</strong> Pagar o preço dos serviços objeto do
                presente contrato nos termos da tabela de preços em cada momento
                em vigor.
              </li>
            </ul>
          </ul>
        </p>
        <br />
        <p>
          <strong>7. NÃO CUMPRIMENTO DAS OBRIGAÇÕES DO CLIENTE</strong>
          <br />
          <br />
          <ul>
            <li>
              <strong>7.1.1.</strong> Mediante o não cumprimento das obrigações
              do Cliente, descritas na cláusula 6, o Cliente poderá sofrer a
              penalização de:
            </li>
            <ul>
              <li>
                <strong>7.2.</strong> Veículo sem inspeção periódica
                obrigatória: penalização de 80 euros (o Parque não se
                responsabiliza por qualquer sinistro sofrido com a viatura);
              </li>
              <li>
                <strong>7.3.</strong> Veículo sem seguro: penalização de 80
                euros (o Parque não se responsabiliza por qualquer sinistro
                sofrido com a viatura);
              </li>
              <li>
                <strong>7.4.</strong> Veículo sem combustível: penalização de 30
                euros mais o valor do combustível colocado na viatura;
              </li>
              <li>
                <strong>7.5.</strong> Veículo sem documentos: penalização de 30
                euros;
              </li>
              <li>
                <strong>7.6.</strong> Veículo sem chave: penalização de 30 euros
                mais o valor do reboque.
              </li>
            </ul>
          </ul>
        </p>
        <br />

        <p>
          <strong>8. ENTREGA E RECOLHA DA VIATURA</strong>
          <br />
          <br />
          <ul>
            <li>
              <strong>8.1.</strong> O Cliente deverá entregar a viatura sem
              qualquer bem que não seja parte integrante desta, seja no
              habitáculo ou na bagageira, visível ou não, designadamente
              equipamentos, rádios, computadores, câmaras, roupas ou qualquer
              outro bem fisicamente apropriável;
            </li>
            <li>
              <strong>8.2.</strong> O representante do Parque deverá proceder a
              um check-in do Veículo, no qual será efetuada uma vistoria ao
              exterior da mesma, de forma a registar a quilometragem, danos,
              desgastes ou defeitos existentes à data da entrega, registo esse
              que se manterá até 2 anos na posse da empresa;
            </li>
            <li>
              <strong>8.3.</strong> O Cliente deverá proceder à assinatura do
              documento disponibilizado pela Empresa, em que declara que não
              deixa no Veículo quaisquer objetos, incluindo os referidos na
              alínea a), e que confirma a quilometragem do Veículo e outros
              factos que possam ter relevância para efeitos de responsabilidade
              do Parque;
            </li>
            <li>
              <strong>8.4.</strong> A receção do veículo pelo Cliente será
              precedida de um check-out, com uma vistoria pelo Cliente e
              representante da Empresa, de forma a confirmar a quilometragem da
              viatura, a inexistência de danos ou quaisquer responsabilidades
              por parte da Empresa. Alguma reclamação que o Cliente pretenda
              efetuar deverá ser realizada nas instalações da Empresa, no
              Redpark;
            </li>
            <li>
              <strong>8.5.</strong> Tendo em conta a receção e o check-out a ser
              realizado nos termos da cláusula anterior, quaisquer reclamações
              que o Cliente possa apresentar relativamente a danos ou
              deficiências deverão ser remetidas no ato da receção do Veículo,
              sob pena de caducar o direito de o Cliente reclamar.
            </li>
          </ul>
        </p>
        <br />

        <p>
          <strong>9. RESPONSABILIDADE DA EMPRESA</strong>
          <br />
          <br />
          <ul>
            <li>
              <strong>9.1.</strong> A Empresa será responsável perante o Cliente
              pelos danos que sejam causados pelos seus colaboradores,
              estritamente nos casos seguintes:
            </li>
            <ul>
              <li>
                <strong>9.1.1.</strong> Danos causados no Veículo em
                consequência do manuseamento dentro do Parque para os efeitos
                previstos nas cláusulas 4.2 b) e c);
              </li>
              <li>
                <strong>9.1.2.</strong> Quaisquer outros danos decorrentes de
                factos ilícitos com dolo ou culpa grave por parte de
                colaboradores, representantes ou agentes da Empresa e cuja
                responsabilidade não possa ser objeto de exclusão nos termos da
                cláusula 8.2 ou do visionamento de imagens de circuito fechado
                propriedade da Empresa;
              </li>
            </ul>
            <li>
              <strong>9.2.</strong> Fica expressamente excluída a
              responsabilidade da Empresa quando respeite a:
            </li>
            <ul>
              <li>
                <strong>9.2.1.</strong> Danos sofridos pelo Cliente
                relativamente a furto de quaisquer bens que hajam ficado na
                viatura após a sua entrega, em desrespeito do previsto na
                cláusula 8.1 a);
              </li>
              <li>
                <strong>9.2.2.</strong> Quaisquer danos elétricos, mecânicos ou
                de desgaste fruto de uso contínuo da viatura;
              </li>
              <li>
                <strong>9.2.3.</strong> Quaisquer danos causados por terceiros
                em resultado de intrusão ilegítima destes no Parque, mesmo que
                tenham conseguido burlar as medidas de segurança tais como
                portões, alarmes e câmaras de segurança, não obstante os
                sistemas de vedação, alarme e vigilância montados pela Empresa;
              </li>
              <li>
                <strong>9.2.4.</strong> Quebra de vidros, para-brisas ou outros
                vidros danificados;
              </li>
              <li>
                <strong>9.2.5.</strong> Pneus danificados ou perfurados;
              </li>
              <li>
                <strong>9.2.6.</strong> Danos causados por catástrofes naturais,
                guerras, atos de terrorismo, quedas de aeronaves ou quaisquer
                outros eventos de força maior que não possam ser controlados
                pela Empresa.
              </li>
            </ul>
          </ul>
        </p>
        <br />

        <p>
          <strong>10. RESPONSABILIDADE DO CLIENTE</strong>
          <br />
          <br />
          <ul>
            <li>
              <strong>10.1.</strong> Serão da responsabilidade do Cliente
              quaisquer multas, coimas ou responsabilidades que a Empresa haja
              que pagar em virtude de qualquer irregularidade verificada na
              Viatura, na sua documentação ou na sua titularidade;
            </li>
            <li>
              <strong>10.2.</strong> No caso de não pagamento pelo Cliente do
              preço contratado ou de quaisquer outras despesas suportadas pela
              Empresa, assiste a esta o direito de retenção sobre a viatura
              (cfr. Art. 755 n.º1 al. e) do Código Civil), a qual será liberada
              assim que o valor em dívida seja integralmente pago, incluindo o
              preço devido em virtude do acréscimo de dias de parqueamento da
              viatura, taxado a 10 euros diários e acrescido dos juros de mora;
            </li>
          </ul>
        </p>
        <br />

        <p>
          <strong>11. DADOS PESSOAIS</strong>
          <br />
          <br />
          Os dados recolhidos sobre o Cliente destinam-se ao cumprimento das
          obrigações legais e à utilização nas relações contratuais com
          Seguradoras, Bancos e outras Instituições, comprometendo-se a Empresa
          a guardar rigoroso sigilo sobre todos os dados, reservando o acesso
          aos mesmos exclusivamente ao necessário para a sua atividade normal.
          Ao respetivo titular são assegurados os direitos de acesso e de
          retificação dos mesmos.
        </p>
        <br />

        <p>
          <strong>12. DISPOSIÇÕES DIVERSAS</strong>
          <br />
          <br />
          <ul>
            <li>
              <strong>12.1.</strong> Qualquer expressão com finalidade de
              denegrir sem fundamento a imagem da Empresa sujeita o seu autor às
              normas de Responsabilidade Criminal e Civil e pelos danos
              causados;
            </li>
            <li>
              <strong>12.2.</strong> A Empresa não se responsabiliza por
              acidentes, quaisquer danos de natureza ilícita ou criminal
              causados aos veículos estacionados, nem pelo desaparecimento de
              objetos existentes no interior dos veículos, pelo seu furto ou
              roubo ou por desastres naturais, tais como a queda de árvores, bem
              como outros danos não intencionais que possam ocorrer;
            </li>
            <li>
              <strong>12.3.</strong> As reclamações referentes ao Parque de
              Estacionamento podem ser registadas no livro de reclamações.
            </li>
          </ul>
        </p>
        <br />

        <p>
          <strong>Aviso Legal</strong>
          <br />
          O presente aviso legal estabelece as condições de uso do espaço web “www.redpark.pt” que Multipark SA, (em diante, Nome Empresa), com domicílio social na Rua Particular, Quinta de Santa Rosa, nº12, 2680-583, Camarate, Lisboa, email info@redpark.pt, com a identificação fiscal número 514164778 e inscrita na Conservatória do Registro Comercial de Lisboa.
          <br />
          O acesso ao espaço web é atribuído a um Utilizador e implica a aceitação, plena e sem reservas, por parte do Utilizador de todas e cada uma das condições presentes neste Aviso Legal.
          <br />
          Todos os direitos de propriedade intelectual deste domínio eletrónico, bem como dos seus conteúdos, pertencem à empresa e a terceiros, pelo que nenhum Utilizador tem autorização de fazer uso destes, nem imprimir ou guardar em nenhum tipo de suporte a não ser para uso pessoal e privado. Fica, pois, proibida a alteração, a separação ou utilização comercial de qualquer parte do mesmo. Apenas se autoriza a utilização dos conteúdos deste domínio eletrónico para fins informativos e de serviço, sempre que se cite ou se faça referência à fonte, sendo o Utilizador o único responsável pelo uso erróneo dos mesmos.
          <br />
          Para ter acesso à informação contida neste espaço web e utilizar os serviços oferecidos, os menores de idade devem ter autorização dos pais, tutores ou representantes legais.
          <br />
          Os dados, textos, informações, gráficos e ligações publicados nesta web são compilados para efeitos meramente informativos para todas as pessoas interessadas nos mesmos, sem que o seu acesso origine uma relação comercial, contratual ou profissional entre o Utilizador e a empresa. Em caso de discrepância entre a informação contida na web e a contida em suporte de papel, prevalecerá a última.
          <br />
          A empresa reserva-se o direito de modificar os conteúdos do domínio web sem prévio aviso. As hiperligações e hipertextos aos quais se aceda através deste espaço web e que prestem serviços oferecidos por terceiros não pertencem nem se encontram sob o controlo da empresa, pelo que esta não se responsabiliza nem pela informação contida nos mesmos nem por qualquer efeito que possa surgir dessa informação.
          <br />
          Mais se informa que os dados incluídos neste formulário, conforme o previsto na Lei Orgânica 15/1999 de Proteção de Dados de Carácter Pessoal, vão fazer parte de um ficheiro automatizado da responsabilidade da empresa, em cumprimento da Lei da Proteção de Dados Pessoais 67/98 de 24 de Outubro e da Lei 41/2004 de 12 de Julho de proteção de Privacidade no setor das Comunicações Eletrónicas. A finalidade da recolha de dados é informar os interessados sobre a empresa, os seus serviços e/ou produtos. Em caso de se negar a comunicar os dados, será impossível ceder informações. Poderá enviar as suas comunicações e exercer os direitos de acesso, correção, cancelamento e oposição através da direção de correio eletrónico ou correio na morada indicada acima.
          <br />
          Os dados fornecidos pelos Utilizadores através da web e/ou correio eletrónico poderão ser usados para o envio de publicidade, tanto através de mensagens “SMS” para telemóvel como para o correio eletrónico.
          <br />
          O Utilizador não pode, sob condição alguma, introduzir qualquer tipo de vírus no domínio web, bem como tentar aceder aos dados do mesmo, modificá-los, aceder às suas contas de correio, mensagens e outros.
          <br />
          A empresa não garante a qualidade, exatidão, fiabilidade, correção ou moral dos dados, programas, informações ou opções, qualquer que seja a sua origem, que circulem na sua rede ou redes a que o Utilizador aceda através da web. O Cliente aceita expressamente manter a empresa isenta de qualquer responsabilidade relacionada com a web. O Utilizador assume como sua exclusiva responsabilidade as consequências, danos ou ações que possam derivar do acesso aos referidos conteúdos, assim como à sua reprodução ou difusão.
          <br />
          A empresa não será responsável pelas infrações cometidas por qualquer Utilizador que afetem os direitos de outros Utilizadores da web ou de terceiros, incluindo os direitos de copyright, marcas, patentes, informação, confidencialidade e qualquer outro direito de propriedade intelectual ou industrial.
          <br />
          A empresa fará uso das ações civis ou penais que por lei lhe correspondam diante de qualquer utilização indevida do seu domínio web. Os litígios que possam surgir em relação a este domínio serão regidos pelo direito espanhol e submetidos a Júris e Tribunais competentes na região de Lisboa.
        </p>
        <br />

        <p>
          <strong>POLÍTICA DE COOKIES</strong>
          <br />
          <br />
          Cookie é um arquivo que é baixado para o seu computador ao aceder a
          determinadas páginas da web. Os cookies permitem que um site, entre
          outras coisas, armazene e recupere informações sobre os hábitos de
          navegação de um utilizador ou do seu computador, dependendo das
          informações que eles contêm e da forma como o utilizador usa o seu
          computador, podendo ser usados para reconhecer o utilizador. O
          navegador do utilizador armazena cookies no seu disco rígido apenas
          durante a sessão atual, ocupando um espaço de memória mínimo e não
          prejudicando o computador. Os cookies não contêm nenhuma informação
          pessoal específica e a maioria deles são excluídos do disco rígido no
          final da sessão do navegador (os chamados cookies de sessão). A
          maioria dos navegadores aceita cookies como padrão e,
          independentemente disso, permitem ou impedem, nas configurações de
          segurança, os cookies temporários ou armazenados. Sem o seu
          consentimento expresso através dos cookies ativados no seu browser, a
          Multipark SA não irá ligar os dados dos cookies armazenados com os
          dados pessoais fornecidos no momento da inscrição ou da compra.
          <br />
          <br />
          <strong>Quais são os tipos de cookies utilizados neste site?</strong>
          <ul>
            <li>
              <strong>Cookies técnicos:</strong> São aqueles que permitem ao
              utilizador navegar através de um site, plataforma ou aplicação e
              utilizar as diferentes opções ou serviços que existem, como, por
              exemplo, o controle de tráfego e comunicação de dados, identificar
              a sessão, acesso a áreas restritas, lembrar os elementos de um
              pedido, realizar o processo de compra de um pedido, fazer um
              pedido de registo ou participação em um evento, usar recursos de
              segurança durante a navegação, armazenar conteúdo para transmissão
              de vídeo ou som ou compartilhar conteúdo via redes sociais.
            </li>
            <li>
              <strong>Cookies de personalização:</strong> São aqueles que
              permitem que os utilizadores acedam ao serviço com algumas
              características de caráter geral, com base em um conjunto
              pré-definido de critérios no terminal do utilizador, tais como a
              língua, o tipo de navegador através do qual acede ao serviço, a
              configuração regional de onde acede ao serviço, etc.
            </li>
            <li>
              <strong>Cookies de análise:</strong> Aqueles que, bem tratados por
              nós ou por terceiros, nos permitem quantificar o número de
              utilizadores e assim realizar a medição e análise estatística do
              uso feito pelos utilizadores do serviço oferecido. Para isso,
              analisamos a sua navegação no nosso site, com o objetivo de
              melhorar a oferta de produtos ou serviços que oferecemos.
            </li>
            <li>
              <strong>Cookies de publicidade:</strong> São aqueles que, bem
              tratados por nós ou por terceiros, nos permitem gerir da forma
              mais eficaz possível a oferta de espaços publicitários no site,
              adaptando o conteúdo do anúncio ao conteúdo do serviço solicitado
              ou ao uso que faz do nosso site. Para isso, analisamos os seus
              hábitos de navegação na internet e podemos mostrar-lhe publicidade
              relacionada com o seu perfil de navegação.
            </li>
            <li>
              <strong>Cookies de publicidade comportamental:</strong> São
              aqueles que permitem a gestão, da forma mais eficiente, dos
              espaços publicitários que o editor tenha incluído num site,
              aplicação ou plataforma a partir da qual presta o serviço
              solicitado. Estes cookies armazenam informações sobre o
              comportamento do utilizador obtidas através da observação contínua
              dos seus hábitos de navegação, permitindo desenvolver um perfil
              específico para exibir anúncios baseados nesse perfil.
            </li>
          </ul>
          <br />
          <strong>Cookies de terceiros:</strong> O site da Multipark SA pode
          utilizar serviços de terceiros em nome da Multipark SA, para compilar
          informações para fins estatísticos, sobre o uso do site pelo utilizador
          e para a prestação de outros serviços relacionados com a atividade do
          site e outros serviços da Internet. Em particular, este site utiliza:
          <ul>
            <li>
              <strong>Google Analytics:</strong> Um serviço de analítica web
              fornecido pela Google, Inc., registrada nos Estados Unidos, com sede
              em 1600 Amphitheatre Parkway, Mountain View, California 94043. Para
              fornecer esses serviços, eles utilizam cookies para compilar
              informações, incluindo o endereço IP do utilizador, que serão
              transmitidas, processadas e armazenadas pelo Google nos termos
              previstos no site Google.com. Inclui-se a possível transmissão de tais
              informações a terceiros por motivos de exigência legal ou caso tais
              terceiros processem a informação em nome do Google.
            </li>
            <li>
              <strong>PHPSESSID Técnico:</strong> Este cookie é usado pela linguagem
              de programação PHP para permitir que as variáveis de sessão sejam
              armazenadas no servidor web. Este cookie é essencial para o
              funcionamento do site.
            </li>
          </ul>
          <br />
          O Utilizador concorda expressamente, ao utilizar este site, com o
          tratamento das informações recebidas, na forma e para os fins acima
          referidos. Reconhece também a possibilidade de recusar o tratamento
          desses dados ou informações, rejeitando o uso de cookies, selecionando
          as configurações apropriadas para esse efeito no seu navegador. Embora
          esta opção de bloquear os cookies no seu navegador possa não permitir
          a plena utilização de todas as funcionalidades do site.
          <br />
          <br />
          Pode permitir, bloquear ou apagar os cookies instalados no seu
          computador configurando as opções do navegador instalado no seu
          dispositivo:
          <ul>
            <li>Chrome</li>
            <li>Explorer</li>
            <li>Firefox</li>
            <li>Safari</li>
          </ul>
          <br />
          Em caso de dúvidas sobre esta política de cookies, poderá entrar em
          contato com a Multipark SA através do email: info@redpark.pt
        </p>
      </div>
    </div>
  );
}
