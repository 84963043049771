import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import '../markdown.scss';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export default function ArticlePage() {
    const { id = "" } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [article, setArticle] = useState<any>(null);
    const pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt";

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const docRef = doc(db, 'redpark/blog/articles', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setArticle(docSnap.data());
                }
            } catch (error) {
                console.error("Error getting document: ", error);
            }
            setLoading(false);
        }
        if (id) fetchData();
        else setLoading(false);
    }, [id]);

    if (loading) {
        return (
            <div className='relative flex items-center justify-center w-full min-h-screen'>
                <CircularProgress color="inherit" />
            </div>
        );
    }

    if (!article) {
        return (
            <div className='relative flex items-center justify-center w-full min-h-screen'>
                <p>Artigo não encontrado.</p>
            </div>
        );
    }

    return (
        <div className='w-full py-10 bg-[#f0f0f0] min-h-screen flex flex-col items-center gap-6'>
            <div className='w-[90%] cinco:w-[70%] flex flex-col gap-6 text-left'>
                <h1 className='text-[1.2rem] cinco:text-[2rem] font-poppins text-[var(--primary)]'>{article.title}</h1>
                <h2 className='cinco:text-[1.5rem] font-bold'>{article.description}</h2>
                <div>
                    <p>
                        {pt ? 'De:' : 'By:'} <span className='font-bold'>{article.author}</span>
                    </p>
                    <p>
                        {pt ? 'Publicado no dia:' : 'Publish date:'} <span className='font-bold'>{article.publishDate}</span>
                    </p>
                </div>
                <img src={article.image} alt="imagem do artigo" className='h-[20rem] object-cover' />
            </div>
            <div
                className='article-content relative py-[2rem] w-[90%] cinco:w-[70%] border-y-[1px] border-[grey] flex flex-col items-center min-h-[25rem] gap-6 text-left'
                dangerouslySetInnerHTML={{ __html: article.article }} // Rendering the article content with HTML formatting
            >
            </div>
        </div>
    );
}
