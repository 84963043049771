import React, { useEffect, useState } from 'react';

import ScheduleIcon from '../../../content/icons/schedule.png';
import SocialIcon from '../../../content/icons/social.png';
import HomeIcon from '../../../content/icons/home.png';

export default function Data() {
    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='relative min-h-screen bg-white px-[8vw] pt-[5rem]'>
            <h2 className='font-bold text-[2rem]'>{lang === 'pt' ? 'Grupo Red Park em Números' : 'Red Park Group in Numbers'}</h2>
            <div className='mt-[4rem] seis:mt-[8rem] flex seis:flex-row flex-col justify-center gap-[10vw]'>
                <div className='flex flex-col items-center gap-2'>
                    <div className='w-[5rem] h-[5rem] bg-[var(--primary)] rounded-full'>
                        <img src={ScheduleIcon} alt="icon" className='w-full' />
                    </div>
                    <p className='font-bold text-[2rem]'>530</p>
                    <p className='text-[1.2rem] text-center'>{lang === 'pt' ? 'Carros por semana' : 'Cars for the Week'}</p>
                </div>
                <div className='flex flex-col items-center gap-2'>
                    <div className='w-[5rem] h-[5rem] bg-[var(--primary)] rounded-full'>
                        <img src={SocialIcon} alt="icon" className='w-full' />
                    </div>
                    <p className='font-bold text-[2rem]'>75.000</p>
                    <p className='text-[1.2rem] text-center'>{lang === 'pt' ? 'Clientes' : 'Customers'}</p>
                </div>
                <div className='flex flex-col items-center gap-2'>
                    <div className='w-[5rem] h-[5rem] bg-[var(--primary)] rounded-full'>
                        <img src={HomeIcon} alt="icon" className='w-full' />
                    </div>
                    <p className='font-bold text-[2rem]'>132</p>
                    <p className='text-[1.2rem] text-center'>{lang === 'pt' ? 'Agências Parceiras' : 'Partner Agencies'}</p>
                </div>
            </div>
            <div className='flex justify-center w-full mt-[5rem]'>
                <button className=' border-4 border-[var(--primary)] rounded-full w-[15rem] py-4 font-bold text-black hover:bg-[var(--primary)] hover:text-white transition-all duration-300' onClick={() => window.location.href="/book"}>{lang === 'pt' ? 'RESERVAR' : 'BOOK'}</button>
            </div>
        </div>
    );
}

