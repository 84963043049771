import React from 'react';
import Logo from '../content/images/redpark.png';

export default function Loading() {
    return (
        <div className='absolute top-0 left-0 w-full h-screen bg-[white] flex flex-col justify-center items-center z-[99] text-black gap-6'>
            <img src={Logo} alt="logo" className='w-[25rem]' />
            <p className='font-bold'>Loading...</p>
        </div>
    );
}

