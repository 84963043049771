import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../markdown.scss';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function ArticlePage() {
    const { id = "" } = useParams();
    const navigate = useNavigate();
    const pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt";

    const [loading, setLoading] = useState<boolean>(true);
    const edit = window.location.pathname.includes('editArticle');
    const [article, setArticle] = useState<any>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                let docRef = doc(db, 'redpark/blog/articles', id);
                let docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setArticle(docSnap.data());
                }
            } catch (error) {
                console.error("Error getting document: ", error);
            }
            setLoading(false);
        }
        if (id) fetchData();
        else {
            setArticle(
                {
                    title: 'Titulo do Artigo',
                    description: 'Descrição do Artigo',
                    author: 'Autor do Artigo',
                    publishDate: new Date().toLocaleDateString(),
                    image: 'https://via.placeholder.com/200',
                    article: '<p>Texto do Artigo</p>',
                    draft: false
                }
            );
            setLoading(false);
        }
    }, [id]);

    const handleInputChange = (field: string, value: any) => {
        setArticle((prev: any) => ({
            ...prev,
            [field]: value
        }));
    };

    const uploadImage = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.click();
        input.onchange = () => {
            const file: any = input.files?.[0];
            if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
                setImageFile(file);
                const imageURL = URL.createObjectURL(file);
                setArticle((prev: any) => ({
                    ...prev,
                    image: imageURL
                }));
            } else {
                console.error("Only png and jpg/jpeg allowed.");
                alert('Apenas png e jpg/jpeg são permitidos.');
            }
        };
    };

    const uploadImageToStorage = async (file: File) => {
        try {
            const storageRef = ref(storage, `redpark-blog/${file.name}`);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            return downloadURL;
        } catch (error) {
            console.error("Error uploading image: ", error);
            alert('Erro ao fazer upload da imagem.');
            return null;
        }
    };

    const action = async () => {

        // Validate article
        if (!article.title || !article.description || !article.author || (!imageFile && !edit) || !article.article) {
            alert('Preencha todos os campos!');
            return;
        }
        setLoading(true);
        const newArticle = article;
        try {

            if (imageFile) {
                const imageUrl = await uploadImageToStorage(imageFile);
                if (imageUrl) {
                    newArticle.image = imageUrl;
                }
            }

            if (edit) {
                const docRef = doc(db, 'redpark/blog/articles', id);
                await updateDoc(docRef, article);
                alert('Artigo atualizado com sucesso!');
                navigate("/admin");
            } else {
                const collectionRef = collection(db, 'redpark/blog/articles');
                await addDoc(collectionRef, article);
                alert('Artigo criado com sucesso!');
                navigate("/admin");
            }
        } catch (error) {
            console.log(newArticle);
            console.error("Error during document action: ", error);
            alert(edit ? 'Erro ao atualizar o artigo!' : 'Erro ao criar o artigo!');
        }
        setLoading(false);
    };

    const goBack = () => {
        if (window.confirm('Deseja mesmo sair? As alterações não salvas serão perdidas.'))
            navigate(-1);
    };

    if (!article) {
        return (
            <div className='relative flex items-center justify-center w-full min-h-screen'>
                <CircularProgress color="inherit" />
            </div>
        );
    }

    return (
        <div className='w-full py-10 bg-[#f0f0f0] min-h-screen flex flex-col items-center gap-6'>
            <div className='flex items-center justify-between cinco:w-[70%] w-[90%] gap-4'>
                <button onClick={goBack} className='px-4 py-2 text-white bg-yellow-500 rounded-full'>
                    Voltar
                </button>
                <button onClick={action} disabled={loading} className='px-4 py-2 text-white bg-green-500 rounded-full'>
                    {edit ? 'Atualizar' : 'Criar'} Artigo
                </button>
            </div>
            <div className='relative flex flex-col items-center w-full gap-6 pt-6 text-left'>
                <div className='w-[90%] cinco:w-[70%] flex flex-col gap-6 text-left'>
                <div className='flex items-center gap-4'>
                    <input
                        type="checkbox"
                        placeholder=''
                        checked={article?.draft}
                        onChange={
                            () => setArticle((prev: any) => ({
                                ...prev,
                                draft: !prev.draft
                            }))
                        }
                        className='accent-[var(--primary)] w-6 h-6'
                    />
                    <p>Rascunho</p>
                </div>
                    <input
                        type="text"
                        value={article.title}
                        onChange={(e) => handleInputChange('title', e.target.value)}
                        className='text-[2rem] font-poppins text-[var(--primary)] w-full bg-[transparent]'
                    />
                    <textarea
                        value={article.description}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        className='text-[1.5rem] font-bold w-full bg-[transparent] resize-none'
                    />
                    <div>
                        <p>
                            {pt ? 'De:' : 'By:'}{' '}
                            <input
                                type="text"
                                value={article.author}
                                onChange={(e) => handleInputChange('author', e.target.value)}
                                className='bg-[transparent] font-bold'
                            />
                        </p>
                        <p>
                            Publicado no dia <span className='font-bold'>{article.publishDate}</span>
                        </p>
                    </div>
                    <div>
                        <button onClick={uploadImage} className='bg-[var(--primary)] rounded-full text-white px-4 py-2'>Upload Image</button>
                    </div>
                    <img src={article.image} alt="imagem do artigo" className='h-[20rem] object-cover' />
                </div>
                <div
                    className='article-editor relative py-[2rem] w-[90%] cinco:w-[70%] border-y-[1px] border-[grey] flex flex-col items-center min-h-[25rem] gap-6 text-left'
                >
                    <CKEditor
                        editor={ClassicEditor}
                        data={article.article}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            handleInputChange('article', data);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
